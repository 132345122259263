<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        权限说明
      </h1>
      <div class="text notitle">
        为了保证您正常的游戏体验，将向您申请获取以下权限：<br><br>

        储存权限：下载和安装游戏更新内容, 拒绝授权将可能无法正常进行游戏<br><br>

        电话权限：需要申请电话权限以获取设备标识(AndroidID、mac)，生成帐号、保存和恢复游戏数据<br><br>

        后台运行权限：允许程序在手机屏幕关闭后后台进程仍然运行，防止游戏进程断开，返回游戏时无需重启游戏<br><br>

        网络状态权限：允许获取当前WiFi接入的状态以及WLAN热点的信息，确保游戏在联网情况下才可正常运行<br><br>

        获取应用安装列表权限：收集应用程序性能、崩溃等情况，为了预防恶意程序以及安全运营所必需<br><br>

        麦克风权限：需要访问麦克风，以提供语音服务功能
      </div>
      <h1 :dir="dir">
        以下是360渠道集成所需权限
      </h1>
      <div class="text">
        允许应用程序执行NFC近距离通讯操作<br><br>
        允许应用程序发送短信<br><br>
        允许应用程序读取联系人通讯录信息<br><br>
        允许应用程序修改当前设置，如本地化<br><br>
        允许应用程序访问闪光灯<br><br>
        允许应用程序查询快捷方式<br><br>
        允许应用程序开机自动运行<br><br>
        允许应用程序读取或写入系统设置<br><br>
        允许应用程序连接配对过的蓝牙设备<br><br>
        允许应用程序获取当前或最近运行的应用<br><br>
        允许应用程序打开系统窗口，显示其他应用程序的顶层<br><br>
        允许应用程序接收短信<br><br>
        允许应用程序重新启动其他程序<br><br>
        允许应用程序拨打电话,从非系统拨号器里初始化一个电话拨号<br><br>
        允许应用程序挂载、卸载外部文件系统<br><br>
        允许访问振动设备<br><br>
        允许应用程序修改全局音频设置<br><br>
        允许应用程序注册和接收C2DM Push消息<br><br>
        允许应用程序读取系统底层日志<br><br>
        允许应用程序访问GMail账户列表<br><br>
        允许应用程序请求从AccountManager验证<br><br>
        允许应用程序读取用户日历数据<br><br>
        允许应用程序写入用户日程，但不可读取<br><br>
        允许应用程序访问通知策略<br><br>
        必须要求由NotificationListenerService，以确保只有系统可以绑定到它<br><br>
        android.permission.REQUEST_INSTALL_PACKAGES<br><br>
        android.webkit.permission.PLUGIN<br><br>
        android.permission.BIND_JOB_SERVICE<br><br>
        android.permission.SYSTEM_OVERLAY_WINDOW<br><br>
        android.permission.receive_ACTION_V5_UPDATE<br><br>
        android.permission.NEWS_SDK_BROADCAST<br><br>
        com.asus.msa.SupplementaryDID.ACCESS<br><br>
        com.google.android.finsky.permission.BIND_GET_INSTALL_REFERRER_SERVICE
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,

  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
